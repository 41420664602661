<template>
  <div class="">
    <MenuUser />
    <div v-if="ready" id="sinistre-card">
      <b-row class="mt-1 p-4">
        <b-col offset-md="2" md="8">
          <b-button variant="primary" size="lg" href="/#/accueil">retour</b-button>
          <!-- <b-card :title="'Sinistre '+sinistre.id" id="chat" class="mt-3"> -->
          <b-card title="Nouveau sinistre" id="chat" class="mt-3">
            <hr class="my-4">
            <div id="card-messages">
              <b-row v-for="(d,i) in data" :key="i" class="mt-2">
                <b-col sm="6" v-if="d.agent === 'user'" class="p-3 text-md-end">
                  <small>{{$store.api.timestampToDate(d.date_create)}}</small>
                </b-col>
                <b-col sm="6">
                  <div class="p-3" :class="d.agent  === 'user'? 'is_user' : 'no_user'" v-html="d.message">
                  </div>
                  <div v-if="d.type === 'options' && d.step === lastStep.step" class="p-1">
                    <b-button size="sm" v-for="(o,i) in d.options" :key="i" :variant="o.color" @click="auto(o,d.key)" class="me-1 mt-1">{{o.text}}</b-button>
                  </div>
                  <div v-if="d.type === 'file' && d.step === lastStep.step" class="p-1 mt-1">
                    <input type="file" placeholder="Choisissez votre document" :data-key="d.key" @change="handleFile" class="form-control"/>
                  </div>
                </b-col>
                <b-col sm="6" v-if="d.agent === 'bot'" class="p-3 text-left">
                  <small>{{$store.api.timestampToDate(d.date_create)}}</small>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6" v-if="loader">
                  <img :src="require('@/assets/images/spinner.svg')" style="width: 35px;height: 35px;">
                </b-col>
              </b-row>
            </div>
            <hr class="my-4">
            <b-input-group>
              <b-form-input v-model="tmpMessage" placeholder="Votre message !" :disabled="lastStep.next === 10000" size="lg"></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-primary" :disabled="lastStep.next === 10000" @click="answer()" size="lg"><i class="fas fa-paper-plane"></i></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script type="text/javascript">
import Vue from 'vue';
import BootstrapVue from "bootstrap-vue";
import MenuUser from '@/components/Menu/Utilisateur';
Vue.use(BootstrapVue)
export default {
  data () {
    return {
      ready:false,
      loader:false,
      tmpMessage:null,
      data:[],
      sinistre:{}
    }
  },
  components: {
    MenuUser
  },
  beforeMount() {
    this.$store.api.ajax('/sinistre/params', null, (res) => {
      if(res.status === true){
        this.params = res.data;
      }
    })
  },
  mounted() {
    if(this.$route.query.id === undefined){
      this.start();
    }else {
      this.$store.api.ajax('/sinistre/'+this.$route.query.id, null, res => {
        if(res.status === true){
          this.sinistre = res.data.sinistre;
          this.data = res.data.chats
          this.ready = true;
        }
      });
    }
  },
  computed:{
    lastStep(){
      var steps = this.data.filter(s => s.agent === "bot");
      var index = steps.length - 1;
      var step = steps[index];
      return step;
    }
  },
  methods: {
    start(){
      this.$store.api.ajax('/sinistre/create', null, res => {
        if(res.status === true){
          this.sinistre = res.data.sinistre;
          this.data = res.data.chats;
          this.ready = true;
        }
      })
    },
    next(){
      var params = {
        sinistre_id: this.sinistre.id,
        step: this.lastStep.step
      }
      this.loader = true;
      this.$store.api.ajax('/sinistre/next', params, res => {
        setTimeout(() => {
          if(res.status === true){
            this.sinistre = res.data.sinistre;
            this.data = res.data.chats;
          }
          this.loader = false;
        },750);
      })
    },
    auto(data, key){
      var params = {
        sinistre_id: this.sinistre.id,
        txt:data.text,
        key:key,
        value:data.value
      }
      this.$store.api.ajax('/sinistre/answer', params, res => {
        if(res.status === true){
          this.sinistre = res.data.sinistre;
          this.data = res.data.chats;
          this.next();
        }
      });
    },
    answer(){
      var data = {
        text:this.tmpMessage,
        value:null
      }
      this.auto(data, null);
      this.tmpMessage = null;
    },
    scrollDown(){
      var objDiv = document.getElementById("card-messages");
      objDiv.scrollTop = objDiv.scrollHeight;
    },
    handleFile(e){
      if(this.loader === true){
        return false;
      }
      this.loader = true;
      var key = e.target.getAttribute('data-key');
      var files = e.target.files;
      const fileUploaded = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        var tmp = {
          'file': e.target.result,
          'file_name': fileUploaded.name
        }
        this.$store.api.ajax('/sinistre/doc', tmp, (res) => {
          if(res.status == true){
            var data = {
              text: res.data,
              value: res.data
            }
            this.auto(data, key);
          }
        })
      }
      reader.readAsDataURL(fileUploaded);
    }
  },
  watch:{
    data:{
      deep:true,
      handler(){
        if(this.lastStep.waiting_anwser === false){
          this.next();
        }
        setTimeout(() => {
          this.scrollDown()
        },50);
      }
    }
  }
}
</script>
<style type="text/scss" lang="scss">
  #sinistre-card {
    @import "../../node_modules/bootstrap/dist/css/bootstrap.min";
    @import "../../node_modules/bootstrap-vue/dist/bootstrap-vue";
  }
</style>
<style scoped>
#chat .is_user {
  background:#3699FF;
  color: white;
  border-radius: 8px;
}
#chat .no_user {
  background:#a5a6af;
  color: white;
  border-radius: 8px;
}
#card-messages {
  height: 550px;
  overflow: scroll;
  padding: 15px;
}
</style>
